<template>
  <VDialog
    v-model="$store.state.ratingBook"
    id="rate"
    persistent
    no-click-animation
  >
    <VCard
      :class="[
        isDark ? 'navy-500-back' : 'white2-back',
        'text-start card-margin padding-20 font-26  radius-12'
      ]"
    >
      <VForm>
        <div :class="[isDark ? 'dark' : 'light', 'pb-12']">
          <h2
            class="font-29 font-bold"
            :class="isDark ? 'gray-400' : 'navy-400'"
          >
            {{ t('$vuetify.rating_book.title') }}
          </h2>
          <p :class="[isDark ? 'gray-500' : 'navy-300', 'pt-6']">
            <!-- {{ t('$vuetify.rating.p') }} -->
          </p>
          <div class="flex-center">
            <img
              :width="45"
              class="star"
              v-for="(star, i) in 5"
              :key="i"
              :src="
                require(`@/assets/icons/star${i + 1}${
                  starRating === i + 1 ? '-orange.svg' : '.svg'
                }`)
              "
              @click="starRating = i + 1"
            />
          </div>
          <label
            v-if="starRating < 3"
            for="reason"
            :class="[isDark ? 'gray-500' : 'navy-300', 'font-bold mt-9 mb-4']"
            >{{ t('$vuetify.rating.reason') }}</label
          >
          <Dropdown
            v-if="starRating < 3"
            popperAppendToBody
            v-model="reason"
            id="reason"
            :options="reasons"
            optionLabel="name"
            :placeholder="t('$vuetify.rating.choose_reason')"
            :class="[
              isDark ? 'navy-500-back' : 'white3-back',
              'w-100 navy-200 gray-300-border white radius-5'
            ]"
          />
          <label
            v-if="reason?.id === 3 && starRating < 3"
            for="problem"
            :class="[isDark ? 'gray-500' : 'navy-300', 'mt-9 mb-4']"
            >{{ t('$vuetify.rating.problem') }}</label
          >
          <InputText
            v-if="reason?.id === 3 && starRating < 3"
            id="problem"
            type="text"
            v-model="problem"
            :placeholder="t('$vuetify.rating.write_reason')"
            :class="[
              isDark ? 'navy-500-back' : 'white3-back',
              current === 'ar' ? 'dir-rtl' : 'dir-ltr',
              'input-field w-100 gray-300-border radius-5'
            ]"
          />
        </div>

        <VBtn
          type="submit"
          :class="[
            width < 768 ? 'w-100' : '',
            'radius-5 orange-500-back white2 font-bold font-29 hi-60'
          ]"
          style="width: calc(50% - 17px); margin-inline-end: 17px"
        >
          {{ t('$vuetify.submit') }}
        </VBtn>
        <VBtn
          :class="[
            isDark ? 'gray-500' : 'navy-300',
            width < 768 ? 'w-100 mt-4' : 'w-50 gray-300-border',
            'font-bold radius-5 hi-60'
          ]"
          @click="$store.state.ratingBook = false"
        >
          {{ t('$vuetify.cancel') }}
        </VBtn>
      </VForm>
      <VBtn
        class="close-button"
        :style="current === 'ar' ? { left: '20px' } : { right: '20px' }"
        @click="store.commit('toggleRatingBook', !store.state.ratingBook)"
      >
        <img
          :width="30"
          :src="
            isDark
              ? require('@/assets/icons/close-dark.svg')
              : require('@/assets/icons/close.svg')
          "
        />
      </VBtn>
    </VCard>
  </VDialog>
</template>
<script>
import store from '@/store'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
export default {
  components: { Dropdown, InputText },
  data() {
    return {
      reason: null,
      reasons: [
        { name: 'فصول مفقودة', id: 0 },
        { name: 'سرد غير صحيح', id: 1 },
        { name: 'صوت تالف', id: 2 },
        { name: 'غير ذلك', id: 3 }
      ],
      problem: '',
      starRating: 5
    }
  }
}
</script>
<script setup>
import { useDark } from '@vueuse/core'
import { useLocale } from 'vuetify'
import { useWindowSize } from '@vueuse/core'
const { t, current } = useLocale()
const isDark = useDark()
const { width } = useWindowSize()
</script>
<style scoped>
.star {
  cursor: pointer;
  transition: all 0.4s;
}
label {
  display: block;
}
.input-field {
  padding: 10px;
  cursor: pointer;
}
.input-field:focus {
  box-shadow: none !important;
}
</style>
<style>
#rate.v-dialog {
  max-width: 887px !important;
}
</style>
