// import home from '../views/Home/index.vue'
export default [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "welcome" */ '../views/Home/index.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () =>
      import(/* webpackChunkName: "welcome" */ '../views/Categories/index.vue')
  },
  {
    path: '/books-by-category/:id',
    name: 'category',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Category/index.vue')
  },
  {
    path: '/book-details/web/:id',
    name: 'BookWeb',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/BookDetails/index.vue')
  },
  {
    path: '/book-details/app/:id',
    name: 'BookApp',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/BookDetails/index.vue')
  }
]
