export default {
  en: 'English',
  ar: 'Arabic',
  welcome: 'Welcome to the Audiobook Library',
  welcome_description: 'Enjoy a world of diverse books',
  start_service: 'ابدأ الخدمة',
  start: 'Start',
  nav: {
    search: 'Search here ...',
    home: 'Home',
    categories: 'Categories',
    en: 'En',
    ar: 'Ar',
    dark: 'Dark',
    light: 'Light'
  },
  footer: {
    child: 'Children',
    help: 'Disabilities',
    danger: 'Report',
    service_stars: 'Service rating',
    dark: 'Dark mode',
    light: 'Light mode'
  },
  categories_title: 'Main Categories',
  popular: 'Trending audiobooks',
  recent: 'Recently added',
  suggested: 'Suggested audiobooks for today',
  wide: {
    minutes: 'Duration',
    author: 'Author',
    category: 'Category',
    lang: 'Language',
    rating: 'Overall User Rating'
  },
  category: {
    books: 'Audiobooks',
    minutes: 'Total Time',
    authors: 'Authors'
  },
  audio_lib: 'Library of audiobooks',
  book: {
    similar: 'Similar Books',
    qr: {
      title: 'Listen to the full audiobook version of this book in two steps',
      p1: "Scan the QR code using your phone's camera",
      p2: "Open the link in your phone's browser"
    },
    audio: {
      title: 'Listen to the audiobook',
      report: 'Report Book',
      report_m: 'Report',
      rating: 'Book Rating',
      rating_m: 'Rating'
    },
    book_info: 'Book Information',
    share: 'Share'
  },
  choose_category: 'Choose the appropriate category',
  submit: 'Confirm',
  cancel: 'Cancel',
  report: {
    title: 'Report a Problem',
    p: 'Did you encounter a problem in the Masmoo app? Let us know!',
    reason: 'Reason for the problem',
    choose_reason: 'Choose the reason',
    problem: 'What problem did you encounter?',
    write_reason: 'Write the reason here'
  },
  report_book: {
    title: 'Report Book Content',
    p: "Did you encounter a problem with the book's content? Let us know!"
  },
  rating: {
    title: 'Service Rating',
    p: 'How do you rate your experience using Masmoo? Let us know!',
    reason: 'What is the reason for the bad rating?',
    choose_reason: 'Choose the reason',
    problem: 'What is the reason for your evaluation?',
    write_reason: 'Write the reason here'
  },
  rating_book: {
    title: 'Book Rating'
  },
  read_more: 'Read more',
  read_less: 'Read less',
  categories: {
    title: 'Main Categories'
  },
  back_categories: 'Back to all categories'
}
