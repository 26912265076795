import { createI18n } from 'vue-i18n'
import ar from '../locales/ar' // Assuming path to your translations file
import en from '../locales/en' // Assuming path to your translations file

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'ar', // Set your default language
  messages: {
    en, // Assuming you have an `en.js` file for English translations
    ar
  }
})

export default i18n
