export default {
  en: 'الانجليزية',
  ar: 'العربية',
  welcome: 'أهلاً بكم في مكتبة الكتب الصوتية',
  welcome_description: 'استمتع بفضاء من الكتب المتنوعة',
  start_service: 'ابدأ الخدمة',
  start: 'Start',
  nav: {
    search: 'ابحث هنا ...',
    home: 'الرئيسية',
    categories: 'الأصناف',
    en: 'الانجليزية',
    ar: 'العربية',
    dark: 'الوضع المظلم',
    light: 'الوضع العادي'
  },
  footer: {
    child: 'أطفال',
    help: 'ذوي الهمم',
    danger: 'الإبلاغ عن مشكلة',
    service_stars: 'تقييم الخدمة',
    dark: 'الوضع المظلم',
    light: 'الوضع العادي'
  },
  categories_title: 'التصنيفات الرئيسية',
  popular: 'تريند الكتب الصوتية',
  recent: 'تمت إضافته مؤخراً',
  suggested: 'الكتب المقترحة لليوم',
  wide: {
    minutes: 'المدة',
    author: 'المؤلف',
    category: 'التصنيف',
    lang: 'اللغة',
    rating: 'تقييم المستخدمين العام'
  },
  category: {
    books: 'الكتب الصوتية',
    minutes: 'إجمالي الوقت',
    authors: 'المؤلفون'
  },
  audio_lib: 'مكتبة الكتب الصوتية',
  book: {
    similar: 'الكتب المشابهة',
    qr: {
      title: 'استمع إلى النسخة الصوتية الكاملة من هذا الكتاب في خطوتين',
      p1: 'امسح رمز الاستجابة السريع باستخدام الكاميرا في هاتفك',
      p2: 'افتح الرابط في متصفح هاتفك'
    },
    audio: {
      title: 'استمع إلى الكتاب الصوتي',
      report: 'ابلاغ عن الكتاب',
      report_m: 'ابلاغ',
      rating: 'تقييم الكتاب',
      rating_m: 'تقييم'
    },
    book_info: 'معلومات الكتاب',
    share: 'مشاركة'
  },
  choose_category: 'اختر التصنيف المناسب',
  submit: 'تأكيد الإرسال',
  cancel: 'إلغاء',
  report: {
    title: 'الابلاغ عن مشكلة',
    p: 'هل واجهت مشكلة في تطبيق مسموع؟ دعنا نعرف!',
    reason: 'سبب المشكلة',
    choose_reason: 'اختر سبب المشكلة',
    problem: 'ماهي المشكلة التي واجهتها؟',
    write_reason: 'اكتب سبب المشكلة هنا'
  },
  report_book: {
    title: 'الابلاغ عن محتوى الكتاب',
    p: 'هل واجهت مشكلة في محتوى الكتاب؟ دعنا نعرف!'
  },
  rating: {
    title: 'تقييم الخدمة',
    p: 'كيف تقيّم تجربة استخدامك لمسموع؟ دعنا نعرف!',
    reason: 'ما سبب التقييم السيء؟',
    choose_reason: 'اختر السبب',
    problem: 'ما هو سبب تقييمك؟',
    write_reason: 'اكتب السبب هنا'
  },
  rating_book: {
    title: 'تقييم الكتاب'
  },
  read_more: 'اقرأ المزيد',
  read_less: 'اقرأ أقل',
  categories: {
    title: 'الأصناف الرئيسية'
  },
  back_categories: 'عودة لجميع الأصناف'
}
