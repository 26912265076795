import { createStore } from 'vuex'

export default createStore({
  state: {
    reportDialog: false,
    ratingDialog: false,
    reportBook: false,
    ratingBook: false,
    audio: false,
    sidebar: false,
    langDialog: false
  },
  getters: {},
  mutations: {
    toggleReport(state, value) {
      state.reportDialog = value
    },
    toggleRating(state, value) {
      state.ratingDialog = value
    },
    toggleReportBook(state, value) {
      state.reportBook = value
    },
    toggleRatingBook(state, value) {
      state.ratingBook = value
    },
    toggleSidebar(state, value) {
      state.sidebar = value
    },
    toggleLang(state, value) {
      state.langDialog = value
    }
  },
  actions: {},
  modules: {}
})
