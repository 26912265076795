<template>
  <v-app :class="[isDark ? 'navy-500-back' : 'white-back', 'font-reg']">
    <v-main class="font-reg">
      <navbar />
      <router-view />
      <Footer />
    </v-main>
    <rating v-if="$store.state.ratingDialog" />
    <report v-if="$store.state.reportDialog" />
    <reportBook v-if="$store.state.reportBook" />
    <ratingBook v-if="$store.state.ratingBook" />
    <transition name="scroll-x-transition">
      <Sidebar v-if="$store.state.sidebar && width < 768" />
    </transition>
    <language v-if="$store.state.langDialog && width < 768" />
  </v-app>
</template>
<script setup>
import { watch } from 'vue'
import { useDark } from '@vueuse/core'
import { useLocale } from 'vuetify'
import { useWindowSize } from '@vueuse/core'
import $ from 'jquery'
const { current } = useLocale()
const isDark = useDark()
current.value = localStorage.getItem('lang') || 'ar'
const font = current.value === 'ar' ? 'sakkal' : 'calibri'
$('.font-bold').css('font-family', `${font}-bold !important`)
$('.font-reg').css('font-family', `${font}-regular !important`)
watch(current, (newVal) => {
  localStorage.setItem('lang', newVal)
  const font = current.value === 'ar' ? 'sakkal' : 'calibri'
  $('.font-bold').css('font-family', `${font}-bold !important`)
  $('.font-reg').css('font-family', `${font}-regular !important`)
})
const { width } = useWindowSize()
console.log('width', window.innerWidth)
console.log('height', window.innerHeight)
</script>
<script>
import navbar from './components/core/navbar.vue'
import Footer from './components/core/Footer.vue'
import language from '@/components/dialogs/language.vue'
import Sidebar from '@/components/core/sidebar.vue'
import rating from './components/dialogs/rating.vue'
import report from './components/dialogs/report.vue'
import reportBook from './components/dialogs/reportBook.vue'
import ratingBook from './components/dialogs/ratingBook.vue'
export default {
  components: {
    navbar,
    Footer,
    language,
    Sidebar,
    rating,
    report,
    reportBook,
    ratingBook
  }
}
</script>
<style lang="scss">
#app,
v-app {
  overflow: hidden !important;
  text-align: center;
  // position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}
.v-main {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
html {
  overflow: hidden !important;
}
v-app {
  font-family: inherit !important; /* Inherits from V-app */
}

/*scroll-handle*/
*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  // background: rgba(255, 255, 255, 0.5) !important;
  background: transparent !important;
  border-radius: 20px !important;
  scroll-padding: 20px !important;
}
*::-webkit-scrollbar-thumb {
  // background: rgba(0, 0, 0, 0.15) !important;
  background: rgba(114, 130, 139, 0.2) !important;
  border-radius: 20px !important;
  // opacity: 0.7 !important;
}
</style>
