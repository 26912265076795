<template>
  <div
    id="footer"
    :class="[
      isDark
        ? 'navy-400-border-2 gray-500 navy-500-back'
        : 'navy-100-border-2 navy-300 white-back',
      'flex-center font-16 font-bold padding-x-4 selected-btn-transparent'
    ]"
  >
    <VContainer fluid class="flex-center">
      <VImg
        :width="192"
        :max-width="192"
        :src="
          isDark
            ? require('@/assets/icons/libraries-commission-dark.svg')
            : require('@/assets/icons/libraries-commission.svg')
        "
        alt="libraries-commission-logo"
      />
      <VSpacer />
      <div class="flex-center">
        <VBtn
          flat
          @click="store.commit('toggleReport', !store.state.reportDialog)"
        >
          <VImg
            class="mx-1"
            :width="17"
            :src="
              isDark
                ? require('@/assets/icons/danger-dark.svg')
                : require('@/assets/icons/danger.svg')
            "
          />
          {{ t('$vuetify.footer.danger') }}
        </VBtn>
        <span
          v-if="width > 768"
          :class="[isDark ? 'gray-400' : 'navy-200', 'font-24']"
          >|</span
        >
        <VBtn
          v-if="width > 768"
          flat
          @click="store.commit('toggleRating', !store.state.ratingDialog)"
        >
          <VImg
            class="mx-1"
            :width="20"
            :src="
              isDark
                ? require('@/assets/icons/star-dark.svg')
                : require('@/assets/icons/star.svg')
            "
          />
          {{ t('$vuetify.footer.service_stars') }}
        </VBtn>
      </div>
    </VContainer>
  </div>
</template>
<script setup>
import { useDark } from '@vueuse/core'
import { useLocale } from 'vuetify'
import { useWindowSize } from '@vueuse/core'
const isDark = useDark()
const { t } = useLocale()
const { width } = useWindowSize()
</script>
<script>
import store from '@/store'
export default {}
</script>
