<template>
  <VDialog v-model="$store.state.langDialog" id="lang">
    <VCard
      :class="[
        isDark ? 'navy-500-back gray-300' : 'white2-back navy-300',
        'text-start padding-20 font-20 radius-8-mixed font-reg'
      ]"
    >
      <div :class="[isDark ? 'navy-400-back' : 'gray-200-back', 'bar']"></div>
      <div class="flex-center my-4">
        <span class="cursor-pointer" @click="changeLanguage('ar')">{{
          t('$vuetify.ar')
        }}</span>
        <VSpacer />
        <VBtn flat @click="changeLanguage('ar')">
          <img
            :src="
              current === 'ar'
                ? require('@/assets/icons/radio-checked.svg')
                : isDark
                ? require('@/assets/icons/radio-dark.svg')
                : require('@/assets/icons/radio-light.svg')
            "
            :width="20"
          />
        </VBtn>
      </div>
      <div class="flex-center my-3">
        <span class="cursor-pointer" @click="changeLanguage('en')">{{
          t('$vuetify.en')
        }}</span>
        <VSpacer />
        <VBtn flat @click="changeLanguage('en')">
          <img
            :src="
              current === 'en'
                ? require('@/assets/icons/radio-checked.svg')
                : isDark
                ? require('@/assets/icons/radio-dark.svg')
                : require('@/assets/icons/radio-light.svg')
            "
            :width="20"
          />
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>
<script setup>
import { useDark } from '@vueuse/core'
import { useLocale } from 'vuetify'
const { t, current } = useLocale()
const isDark = useDark()
function changeLanguage(lang) {
  current.value = lang
}
</script>
<style scoped>
.bar {
  position: absolute;
  top: 0.7rem;
  right: calc(50% - (92px / 2));
  width: 92px;
  height: 4px;
  border-radius: 10px;
}
</style>
<style lang="scss">
#lang .v-overlay__content {
  margin: 0;
  bottom: 0;
  .v-btn {
    min-width: auto;
    width: auto;
    height: auto;
    min-height: auto;
    border-radius: 100px;
    padding: 0 !important;
  }
}
</style>
