<template>
  <VContainer
    id="sidebar"
    :class="[
      isDark ? 'navy-light-back2' : 'white-back',
      'text-start font-20 font-bold selected-btn-transparent'
    ]"
  >
    <VCard :class="[isDark ? 'gray-300' : 'navy-400', 'items padding-x-4']">
      <div
        class="flex-center-space item"
        :class="isDark ? 'navy-400-dashed' : 'gray-300-dashed'"
      >
        <h2
          @click="
            store.commit('toggleSidebar', !store.state.sidebar),
              router.push('/')
          "
          :class="[$route.name === 'home' ? 'orange-500' : '']"
        >
          <VImg
            class="me-4"
            :width="24"
            :src="require('@/assets/icons/home-orange.svg')"
          />
          {{ t('$vuetify.nav.home') }}
        </h2>
        <VBtn
          flat
          :to="'/'"
          @click="store.commit('toggleSidebar', !store.state.sidebar)"
        >
          <img
            :width="16"
            :class="current === 'ar' ? 'rotate-y-180' : ''"
            :src="
              isDark
                ? require('@/assets/icons/arrow-left-dark.svg')
                : require('@/assets/icons/arrow-left-light.svg')
            "
          />
        </VBtn>
      </div>
      <div
        class="flex-center-space item"
        :class="isDark ? 'navy-400-dashed' : 'gray-300-dashed'"
      >
        <h2
          @click="
            store.commit('toggleSidebar', !store.state.sidebar),
              router.push('/categories')
          "
          :class="[
            $route.name === 'categories' || $route.name === 'category'
              ? 'orange-500'
              : ''
          ]"
        >
          <VImg
            class="me-4"
            :width="24"
            :src="require('@/assets/icons/category-orange.svg')"
          />
          {{ t('$vuetify.nav.categories') }}
        </h2>
        <VBtn
          flat
          :to="'/categories'"
          @click="store.commit('toggleSidebar', !store.state.sidebar)"
        >
          <img
            :width="16"
            :class="current === 'ar' ? 'rotate-y-180' : ''"
            :src="
              isDark
                ? require('@/assets/icons/arrow-left-dark.svg')
                : require('@/assets/icons/arrow-left-light.svg')
            "
          />
        </VBtn>
      </div>
      <div
        class="flex-center-space item"
        :class="isDark ? 'navy-400-dashed' : 'gray-300-dashed'"
      >
        <h2 @click="store.commit('toggleLang', !store.state.langDialog)">
          <VImg
            class="me-4"
            :width="27"
            :src="require('@/assets/icons/global.svg')"
          />
          {{ t('$vuetify.wide.lang') }}
        </h2>
        <VBtn flat @click="store.commit('toggleLang', !store.state.langDialog)">
          <span :class="[isDark ? 'gray-300' : 'navy-300', 'font-16 mx-2']">{{
            current === 'ar' ? t('$vuetify.ar') : t('$vuetify.en')
          }}</span>
          <img
            :width="16"
            :class="current === 'ar' ? 'rotate-y-180' : ''"
            :src="
              isDark
                ? require('@/assets/icons/arrow-left-dark.svg')
                : require('@/assets/icons/arrow-left-light.svg')
            "
          />
        </VBtn>
      </div>
      <div
        class="flex-center-space item"
        :class="isDark ? 'navy-400-dashed' : 'gray-300-dashed'"
      >
        <h2 @click="toggleDark()">
          <img
            :src="require('@/assets/icons/moon-orange.svg')"
            alt="moon-icon"
            :width="24"
            class="me-4"
          />
          {{ t('$vuetify.nav.dark') }}
        </h2>
        <InputSwitch v-model="isDark" />
      </div>
      <div class="flex-center-space item">
        <h2
          @click="
            store.commit('toggleRating', !store.state.ratingDialog),
              store.commit('toggleSidebar', !store.state.sidebar)
          "
        >
          <VImg
            class="me-4"
            :width="24"
            :src="require('@/assets/icons/star-orange.svg')"
          />
          {{ t('$vuetify.footer.service_stars') }}
        </h2>
        <VBtn
          flat
          @click="
            store.commit('toggleRating', !store.state.ratingDialog),
              store.commit('toggleSidebar', !store.state.sidebar)
          "
        >
          <img
            :width="16"
            :class="current === 'ar' ? 'rotate-y-180' : ''"
            :src="
              isDark
                ? require('@/assets/icons/arrow-left-dark.svg')
                : require('@/assets/icons/arrow-left-light.svg')
            "
          />
        </VBtn>
      </div>
    </VCard>
    <Footer />
  </VContainer>
</template>
<script>
import Footer from './Footer.vue'
import InputSwitch from 'primevue/inputswitch'
export default {
  components: [InputSwitch]
}
</script>
<script setup>
import { useDark, useToggle } from '@vueuse/core'
import { useLocale } from 'vuetify'
import store from '@/store'
import router from '@/router'

const { t, current } = useLocale()
const isDark = useDark()
const toggleDark = useToggle(isDark)
</script>
<style scoped lang="scss">
#sidebar {
  width: 100vw;
  height: 100%;
  top: 64px;
  position: fixed;
  left: 0;
  z-index: 200;
  overflow: hidden !important;
  .item {
    height: 85px;
    h2 {
      display: flex;
      cursor: pointer;
    }
  }
  .items {
    height: calc(100% - 64px - 56px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<style>
#sidebar .v-btn__content,
#sidebar .v-btn {
  justify-content: flex-start !important;
  min-width: auto !important;
}
</style>
