// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import ar from '../locales/ar'
import en from '../locales/en'

const vuetify = createVuetify({
  locale: {
    locale: 'ar',
    messages: { ar, en },
    rtl: {
      ar: true
    }
  },
  // ssr: false, // Adjust for your server-side rendering needs
  components,
  directives
})

export default vuetify
