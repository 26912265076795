import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { loadFonts } from './plugins/webfontloader'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import PrimeVue from 'primevue/config'
// scss styles
import '@/assets/scss/index.scss'

const app = createApp(App)
app.use(router).use(store).use(vuetify).use(i18n).use(PrimeVue).mount('#app')
