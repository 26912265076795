<template>
  <VContainer
    id="navbar"
    fluid
    class="flex-center gray-300 font-22 font-bold selected-btn-transparent flex-center-space"
    :class="[
      isDark
        ? 'navy-light-back2'
        : width > 768
        ? 'navy-500-back'
        : 'white-back',
      width > 768 ? 'padding-x-4' : 'padding-20-x'
    ]"
  >
    <img
      :src="
        width < 768 && !isDark
          ? require('@/assets/icons/logo-text-dark.svg')
          : require('@/assets/icons/logo-text.svg')
      "
      alt="logo"
      :width="111"
    />
    <div class="flex-center" v-if="width > 768">
      <VBtn
        flat
        :to="'/'"
        :class="[$route.name === 'home' ? 'orange-500' : '']"
      >
        {{ t('$vuetify.nav.home') }}
      </VBtn>
      <VBtn
        flat
        :to="'/categories'"
        :class="[
          $route.name === 'categories' || $route.name === 'category'
            ? 'orange-500'
            : ''
        ]"
      >
        {{ t('$vuetify.nav.categories') }}
      </VBtn>
    </div>
    <div class="flex-center" v-if="width > 768">
      <VBtn flat class="mx-2 px-2">
        <img
          :src="require('@/assets/icons/search-orange.svg')"
          alt="search-icon"
          :width="24"
        />
      </VBtn>
      <VBtn
        flat
        @click="current === 'ar' ? changeLanguage('en') : changeLanguage('ar')"
        class="mx-2 px-2"
      >
        <img
          :src="require('@/assets/icons/global.svg')"
          alt="global-icon"
          :width="24"
        />
      </VBtn>
      <VBtn flat @click="toggleDark()" class="mx-2 px-2">
        <img
          :src="
            !isDark
              ? require('@/assets/icons/moon-orange.svg')
              : require('@/assets/icons/sun.svg')
          "
          :alt="isDark ? 'moon-icon' : 'sun-icon'"
          :width="24"
        />
      </VBtn>
    </div>
    <VBtn @click="store.commit('toggleSidebar', !store.state.sidebar)" v-else>
      <VImg
        v-if="!store.state.sidebar"
        :width="20"
        :src="
          isDark
            ? require('@/assets/icons/toggle-dark.svg')
            : require('@/assets/icons/toggle-light.svg')
        "
      ></VImg>
      <VImg
        v-else
        :width="15.9"
        :src="
          isDark
            ? require('@/assets/icons/close-side-dark.svg')
            : require('@/assets/icons/close-side-light.svg')
        "
      ></VImg>
    </VBtn>
  </VContainer>
</template>
<script>
export default {
  data() {
    return {
      searchValue: ''
    }
  }
}
</script>
<script setup>
import { useDark, useToggle } from '@vueuse/core'
import { useLocale } from 'vuetify'
import store from '@/store'
import { useWindowSize } from '@vueuse/core'

const { current, t } = useLocale()
function changeLanguage(lang) {
  current.value = lang
}
const isDark = useDark()
const toggleDark = useToggle(isDark)
const { width } = useWindowSize()
</script>
<style scoped lang="scss">
#navbar {
  height: 80px;
  z-index: 100;
  position: fixed;
  top: 0px;
  @media screen and (max-width: 768px) {
    height: 64px;
  }
}
.search-cont {
  /* width: 383px; */
  cursor: default !important;
  width: 30% !important;
  height: 65px;
  /* height: 80px; */
  span {
    cursor: pointer;
  }
}
input::placeholder {
  color: #9ba7ad;
}
img {
  /* width: 26px; */
}
</style>
<style>
#navbar .v-btn__content,
#navbar .v-btn {
  justify-content: flex-start !important;
  min-width: auto !important;
}
</style>
